import {
  Box,
  InputAdornment,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb } from "../shared/Breadcrumbs";
import {
  StyledFilterFormControl,
  StyledFormControlLabel,
  StyledPageLayout,
  StyledTab,
  StyledTabBox,
  StyledTabsGroup,
  StyledTextField,
} from "../../theme/StyledEle";
import PropTypes from "prop-types";
import Dropdown from "./Dropdown";
import moment from "moment";
import { BsCalendar2 } from "react-icons/bs";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Dashboards } from "../Constans";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { DashboardTabs } from "../../Utils/Constans";
import { useSelector } from "react-redux";
import {useAuditLogMutation} from "../../store/slice/ApiSlice";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box >{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


export const NewDashboard = () => {
  const [showDashboard, setShowDashboard] = useState("-1");
  const [showCalendar, setShowCalendar] = useState(false);
   const [monthPicker, setMonthPicker] = useState(false);
  const [value, setValue] = React.useState(39);
  const [message, setMessage] = useState("Dashboard > Dashboard");

  const globalJson1 =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;
  var globalUserName = "";
  var globalOrgName = "";
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson?.resellerId;
    globalOrgName = globalJson.orgName;
    globalUserName = globalJson.userName;
  }


  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: globalOrgId,
    userId: globaluserId, 
    orgName: globalOrgName, 
    userName: globalUserName,
  };

  useEffect(() => {
    if (showDashboard === "-1") {
      setShowCalendar(false);
    }
  }, [showDashboard]);

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    setMonthPicker(false);
    if (newValue === 40) {
      setShowCalendar(true);
      auditPayload.message = "Dashboard > Dashboard > Vehicle Summary";
    } else if (newValue === 41) {
      setShowCalendar(true);
      auditPayload.message = "Dashboard > Dashboard > Battery Detaills";
    } else if (newValue === 42) {
      setShowCalendar(true);
      auditPayload.message = "Dashboard > Dashboard > Motor Detaills";
    }else if (newValue === 44) {
      setShowCalendar(true);
      setMonthPicker(true);
      auditPayload.message = "Dashboard > Dashboard > Location Summary";
    }else if (newValue === 45) {
      setShowCalendar(true);
      setMonthPicker(true);
      auditPayload.message = "Dashboard > Dashboard > Fault Details";
    } else {
      setShowCalendar(false);
      auditPayload.message = "Dashboard > Dashboard >Last Connect";
    }

    auditPayload.action = "Select";
    auditPayload.featureName = "Dashboard";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;
    auditLog(auditPayload);
  };

  useEffect(async () => {
    auditPayload.message = "Dashboard > Dashboard";
    auditPayload.action = "View";
    auditPayload.featureName = "Dashboard";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;
    auditLog(auditPayload);
  }, []);

  let userData = JSON.parse(localStorage.getItem("userData"));
  let RoleId = userData?.roleId;
  let orgType = userData?.userOrgType;
  let resellerId = userData.resellerId
  const [appendData, setAppendData] = useState({});
  //Callback
  const callbackLoadFn = (
    regionVal,
    dealerVal,
    seltdCustomerId,
    seltdFleetId,
    seltdVehId
  ) => {
    var loadrequest = {};
    loadrequest.region = globalResellerId === 113802 ? 1 : -1;
    loadrequest.dealer = globalResellerId === 113802 ? 113806 : -1;
    loadrequest.customer = -1;
    loadrequest.fleet = -1;
    loadrequest.vehicle = -1;
    setAppendData(loadrequest);
    if (loadrequest.vehicle === "-1") {
      setValue(39);
    }
  };
  useEffect(() => {
    callbackLoadFn();
  }, []);

  //CalenderValue

  const [dateName, setDateName] = useState("");
  //onsubmit
  const appendRef = useRef();
  let [formData, setFormData] = useState(null);
  const [date, setDate] = useState({ start: null, end: null });
  let [ZohoUrl, setZohoUrl] = useState(null);
  let [locSumUrl, setLocSumUrl] = useState(null);
  let [faultSumUrl, setFaultSumUrl] = useState(null);

  let [lastConnectUrl, setLastConnectUrl] = useState(null);
  let [vehicleSumUrl, setVehicleSumUrl] = useState(null);
  let [batteyUrl, setBatteryUrl] = useState(null);
  let [motorUrl, setMotorUrl] = useState(null);
  let [vehicle, setVehicle] = useState(null);
  let [reload, setReload] = useState(false);
  let iframeReload = useRef();
  //Hide & Show Tabs
  const tabsOpt = useSelector((state) => state.controls.customizer.currentPage.buttonOpt);
  const onSubmitForm = (data) => {
    setFormData(data);
    setDate({ start: data?.fromDate, end: data?.toDate });
    if(iframeReload?.current?.src !== undefined && iframeReload?.current !== null){
      iframeReload.current.src = iframeReload?.current?.src;
    }

  };

  //dashboard urls
  let [lastConnet, setLastConnect] = useState(null);
  let [vehicleSummaryd, setVehicleSummaryd] = useState(null);
  let [motor, setMotor] = useState(null);
  let [batttey, setBattery] = useState(null);
  let [oem, setOem] = useState(null);
  const findDashboard = () => {
    let findUrls = Dashboards?.filter( (item) => item?.resellerId === userData?.resellerId);
    setLastConnect(findUrls[0]?.dashboardName[0].url);
    setVehicleSummaryd(findUrls[0]?.dashboardName[1].url);
    setMotor(findUrls[0]?.dashboardName[3].url);
    setBattery(findUrls[0]?.dashboardName[2].url);
    

    setOem(findUrls[0].oem);

  };

  useEffect(() => {
      findDashboard();
  }, []);



  useEffect(() => {
    var regionId = "";
    var customerId = "";
    var dealerId = "";
    var fleetId = "";
    var vehicleId = "";
    let startTime = moment(date.start, "YYYY-MM-DD HH:mm:ss").valueOf();
    let endTime = moment(date.end, "YYYY-MM-DD HH:mm:ss").valueOf();

    if (formData !== null) {
      if (formData?.regionId !== "-1" && formData?.regionId !== 0 && orgType !== 5 && orgType !== 3 && orgType !== 1) {
        regionId = '"REGION_ID"=' + formData?.regionId + "and";
      } else {
        regionId = oem && orgType !== 1 ?  '"REGION_ID"!=5' + "and" : ""
      }

      if (formData?.dealerId !== "-1" && orgType !== 3 && orgType !== 1) {
        dealerId = '"DEALER_ID"=' + formData?.dealerId + "and";
      } else {
        dealerId = "";
      }

      if (formData?.customerId !== "-1" && orgType !== 1) {
        customerId = '"CUSTOMER_ID"=' + formData?.customerId + "and";
      } else {
        customerId = "";
      }

      if (formData?.fleetId !== "-1") {
        fleetId = '"org_id"=' + formData?.fleetId + "and";
      } else {
        fleetId = "";
      }
      if (formData?.vehicle !== "-1" && formData?.vehicle !== null) {
        setMonthPicker(false);
        vehicleId = formData?.vehicleId;
        setShowDashboard(formData?.vehicleId);
         setValue(39);
      } else {
        vehicleId = "";
        setVehicle(formData?.vehicleId);
        setMonthPicker(false);
        setShowCalendar(false);
        setShowDashboard("-1");
        setValue(39);
        
      }
      // if (formData?.vehicleId !== "-1") {
      //   vehicleId = formData?.vehicleId;
      //   setVehicle(formData?.vehicleId);
      // } else {
      //   vehicleId = "";
      //   setVehicle(formData?.vehicleId);
      //   setValue(39);
      // }
    }
    formData !== null && setZohoUrl(
      userData?.resellerId === 110224
        ? process.env.REACT_APP_PROD_ENV === 'true' ?
        `https://analytics.zoho.in/open-view/353263000000054060?ZOHO_CRITERIA=`+
            regionId +
            dealerId +
            customerId +
            fleetId +
            '"RESELLER_ID"=' +
            userData?.resellerId +
            ""
            :
          `https://analytics.zoho.in/open-view/353263000000019006?ZOHO_CRITERIA=` +
            regionId +
            dealerId +
            customerId +
            fleetId +
            '"RESELLER_ID"=' +
            userData?.resellerId +
            ""
            :
             userData?.resellerId === 110050
              ? process.env.REACT_APP_PROD_ENV === 'true' ?
             `https://analytics.zoho.in/open-view/353263000001455203?ZOHO_CRITERIA=`+
                 regionId +
                 dealerId +
                 customerId +
                 fleetId +
                 '"RESELLER_ID"=' +
                 userData?.resellerId +
                 ""
                 :
               `https://analytics.zoho.in/open-view/353263000001317388?ZOHO_CRITERIA=` +
                 regionId +
                 dealerId +
                 customerId +
                 fleetId +
                 '"RESELLER_ID"=' +
                 userData?.resellerId +
                 ""
                :

            `https://analytics.zoho.in/open-view/353263000000042176?ZOHO_CRITERIA=` +
            regionId +
            dealerId +
            customerId +
            fleetId +
            '"RESELLER_ID"=' +
            userData?.resellerId +
            ""
    );
    
    setLocSumUrl(
      process.env.REACT_APP_PROD_ENV === 'true' ?
      "https://3w-dashboard.montraelectric.com:3000/d/MONTRA3W-LocationBasedJahP26nz-prod/montra3w-location-based-prod?orgId=1" +
        "&from=" +
        startTime +
        "&to=" +
        endTime +
        "&var-Start_date=" +
        date.start +
        "&var-End_date=" +
        date.end +
        "&var-vehicle_id=" +
        vehicleId +
        "&var-region_id=" +
        formData?.regionId +
        "&var-dealer_id=" +
        formData?.dealerId +
        "&var-customer_id=" +
        formData?.customerId +     
        "&var-fleet_id=" +
        formData?.fleetId +     
        '&var-reseller_id=' +
        userData?.resellerId +
        "&kiosk" :

        "https://dashboards-qa.montraelectric.com:3000/d/MONTRA3W-LocationBasedJahP26nz-QA/montra3w-location-based-qa?orgId=1" +
        "&from=" +
        startTime +
        "&to=" +
        endTime +
        "&var-Start_date=" +
        date.start +
        "&var-End_date=" +
        date.end +
        "&var-vehicle_id=" +
        vehicleId +
        "&var-region_id=" +
        formData?.regionId +
        "&var-dealer_id=" +
        formData?.dealerId +
        "&var-customer_id=" +
        formData?.customerId +     
        "&var-fleet_id=" +
        formData?.fleetId +     
        '&var-reseller_id=' +
        userData?.resellerId +
        "&kiosk"

    );

    setFaultSumUrl(
      process.env.REACT_APP_PROD_ENV === 'true' ? 
       "https://3w-dashboard.montraelectric.com:3000/d/VehicleFailureRegion_IDddp52ca1kt3pcc/montra3w-vehicle-failure-dashboard-region-id?orgId=1" +
        "&from=" +
        startTime +
        "&to=" +
        endTime +
        "&var-Start_date=" +
        date.start +
        "&var-End_date=" +
        date.end +
        "&var-vehicle_id=" +
        vehicleId +
        "&var-region_id=" +
        formData?.regionId +
        "&var-dealer_id=" +
        formData?.dealerId +
        "&var-customer_id=" +
        formData?.customerId +     
        "&var-fleet_id=" +
        formData?.fleetId +     
        '&var-reseller_id=' +
        userData?.resellerId +
        "&kiosk" :
        "https://dashboards-qa.montraelectric.com:3000/d/VehicleFailureRegion_IDddp52ca1kt3pcc/montra3w-vehicle-failure-dashboard-region-id?orgId=1" +
        "&from=" +
        startTime +
        "&to=" +
        endTime +
        "&var-Start_date=" +
        date.start +
        "&var-End_date=" +
        date.end +
        "&var-vehicle_id=" +
        vehicleId +
        "&var-region_id=" +
        formData?.regionId +
        "&var-dealer_id=" +
        formData?.dealerId +
        "&var-customer_id=" +
        formData?.customerId +     
        "&var-fleet_id=" +
        formData?.fleetId +     
        '&var-reseller_id=' +
        userData?.resellerId +
        "&kiosk"
    );

    if(showDashboard !== '-1' && showDashboard !== null){
      setLastConnectUrl(
        lastConnet +
          "&var-vehicle_id=" +
          vehicleId +
          "&var-Start_date=" +
          moment().format("YYYY-MM-DD") +
          "&var-End_date=" +
          moment().format("YYYY-MM-DD") +
          "&kiosk"
      );
      let SummaryUrl = resellerId === 110224 && value === 40 && formData.selectedPriod === 7 ? 
      process.env.REACT_APP_PROD_ENV === 'true' ?
      'https://dashboard.montraelectric.com:3000/d/MONTHRATRUCK-Last7daysvs-KFYa2egVk-PROD/monthra-truck-last-7-days-vehicle-summary-prod?orgId=1':
      'https://dashboards-qa.montraelectric.com:3000/d/MONTHRATRUCK-Last7daysvs-KFYa2egVk-PROD/monthra-truck-last-7-days-vehicle-summary-prod?orgId=1' : vehicleSummaryd
      setVehicleSumUrl(
        SummaryUrl +
          "&from=" +
          startTime +
          "&to=" +
          endTime +
          "&var-vehicle_id=" +
          vehicleId +
          "&var-Start_date=" +
          date.start +
          "&var-End_date=" +
          date.end +
          "&var-Date=" +
          date.start +
          "&kiosk"
      );
      setBatteryUrl(
        batttey +
          "&from=" +
          startTime +
          "&to=" +
          endTime +
          "&var-Start_date=" +
          date.start +
          "&var-End_date=" +
          date.end +
          "&var-vehicle_id=" +
          vehicleId +
          "&kiosk"
      );
      setMotorUrl(
        motor +
          "&from=" +
          startTime +
          "&to=" +
          endTime +
          "&var-vehicle_id=" +
          vehicleId +
          "&var-Start_date=" +
          date.start +
          "&var-End_date=" +
          date.end +
          "&var-Date=" +
          date.start +
          "&kiosk"
      );
    }
   
  }, [formData, showDashboard]);

  useEffect(() => {
    if (vehicle !== "-1" && vehicle !== null) {
      setMonthPicker(false);
      setShowDashboard(vehicle);
       setValue(39);
    } else {
      setMonthPicker(false);
      setShowCalendar(false);
      setShowDashboard("-1");
      setValue(39);
      
    }
  }, [vehicle]);

  const theme = useTheme();

  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
    setIframeLoaded(true);
    }, 3000);
   
    return () => clearTimeout(timeoutId);
    }, []);

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Dashboard" subDiv="Dashboard"></Breadcrumb>
      <FilterDropdown
        getData={onSubmitForm}
        last7={resellerId === 110224 && value === 40 ? true : false}
        status={false}
        allowDays={1}
        allowToday={true}
        showCalendar={showCalendar}
        thisMonth = {monthPicker}
        currentMonth = {monthPicker}
        allowedMonth={3}
        time={false}
      />
      {showDashboard === "-1" ? (
        <Box>
          {/* Tabs */}
          <StyledTabBox>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <StyledTab value={39} label="Dashboard" />
              {  userData?.resellerId === 110050 &&
               <StyledTab value={44} label="Location Summary" />
             }
             {userData?.resellerId === 110050 &&
              <StyledTab value={45} label="Fault Details" /> 
             }
            </Tabs>
          </StyledTabBox>
          {/* Tabs Panel */}

          {
            formData !== null && ZohoUrl !== null && iframeLoaded &&
            <CustomTabPanel value={value} index={39}>
            <Box sx={{ height: "100vh" }}>
                <iframe
                className="iframe"
                src={ZohoUrl}
                width="100%"
                height="98%"
                ref={iframeReload}
              ></iframe>
            </Box>
          </CustomTabPanel>
          }
          {
            userData?.resellerId === 110050 && formData !== null && locSumUrl !== null && iframeLoaded &&
            <CustomTabPanel value={value} index={44}>
            <Box sx={{ height: "100vh" }}>
                <iframe
                className="iframe"
                src={locSumUrl}
                width="100%"
                height="98%"
                ref={iframeReload}
              ></iframe>
            </Box>
          </CustomTabPanel>
          }
          {
            userData?.resellerId === 110050 && formData !== null && faultSumUrl !== null && iframeLoaded &&
            <CustomTabPanel value={value} index={45}>
            <Box sx={{ height: "100vh" }}>
                <iframe
                className="iframe"
                src={faultSumUrl}
                width="100%"
                height="98%"
                ref={iframeReload}
              ></iframe>
            </Box>
          </CustomTabPanel>
          }
          
        </Box>
      ) : (
        <Box>
          <Box>
            {/* Tabs */}
            <StyledTabBox>

            <Tabs 
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            >
              {/* {
                DashboardTabs?.map((item) => {
                  if(tabsOpt?.includes(item.valaue)){
                    return( <StyledTab value={item.valaue} label={item.label}/> )
                  } 
                })
              } */}

            </Tabs>

              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <StyledTab value={39} label="Last Connected" />
                <StyledTab value={40} label="Vehicle Summary" />
                {batttey !== '' && orgType !==3 && orgType !==5 && orgType !== 1 && <StyledTab value={41} label="Battery Details" />}
                
                {
                  ///Montra
                  (motor !== ''&& userData?.resellerId !== 109882 && orgType !== 3 && orgType !== 5 && orgType !== 1) ? <StyledTab value={42} label= {userData?.resellerId === 110050 ?"Vehicle Fault":"Motor Profile"} /> :  (userData?.resellerId === 109882 && RoleId !== 7 && RoleId !== 8) && ''
             
                }
              </Tabs>
            </StyledTabBox>
            {/* Tabs Panel */}
            <CustomTabPanel value={value} index={39}>
              <Box sx={{ height: "100vh" }}>
                <iframe
                  id="Contentframe"
                  className="iframe"
                  src={lastConnectUrl}
                  width="100%"
                  height="98%"
                  name="Right"
                  ref={iframeReload}
                ></iframe>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={40}>
              <Box sx={{ height: "100vh" }}>
                <iframe
                  id="Contentframe"
                  className="iframe"
                  src={vehicleSumUrl}
                  width="100%"
                  height="98%"
                  ref={iframeReload}
                ></iframe>
              </Box>
            </CustomTabPanel>
            {
            ///Montra
            batttey !== '' && (
            <CustomTabPanel value={value} index={41}>
              <Box sx={{ height: "100vh" }}>
                <iframe
                  id="Contentframe"
                  className="iframe"
                  src={batteyUrl}
                  width="100%"
                  height="98%"
                  ref={iframeReload}
                ></iframe>
              </Box>
            </CustomTabPanel>
            )
            }
            {
              ///Montra
              motor !== '' && (
                <CustomTabPanel value={value} index={42}>
                  <Box sx={{ height: "100vh" }}>
                    <iframe
                      id="Contentframe"
                      className="iframe"
                      src={motorUrl}
                      width="100%"
                      height="98%"
                      ref={iframeReload}
                    ></iframe>
                  </Box>
                </CustomTabPanel>
              )
            }
          </Box>
        </Box>
      )}
    </StyledPageLayout>
  );
};

export default NewDashboard;
